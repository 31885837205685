// State
export const state = () => ({
    teamCount: 0,
    preFooterInViewport: false,
    contactFormInViewport: false
});

// Mutations
export const mutations = {
    setTeamCount (state, data) {
        state.teamCount = data;
    },
    handlePreFooterInViewport (state, data) {
        state.preFooterInViewport = data;
    },
    handleContactFormInViewport (state, data) {
        state.contactFormInViewport = data;
    }
};
